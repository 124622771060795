<script>
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import BaseButtonRound from '/~/components/base/button-round/base-button-round.vue'
import BaseCounter from '/~/components/base/counter/base-counter.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import CartItemPrice from '/~/components/cart-menu/components/item/cart-item-price.pure.vue'
import EwProductType from '/~/components/ewallet/product/ew-product-type.vue'
import { useCart } from '/~/composables/cart'
import { useCms } from '/~/composables/cms/use-cms'
import { useGiftRecipients } from '/~/composables/gift-recipients'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'cart-item',
  components: {
    EwProductType,
    BaseButtonRound,
    BaseCounter,
    BaseImage,
    CartItemPrice,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    showDelivery: {
      type: Boolean,
      default: true,
    },
    prependEstoreTitleWithPrice: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { calculatePointsToBurnOrder } = usePoints()
    const { cart, updateCartItem, removeCartItem } = useCart()
    const { getGiftRecipient } = useGiftRecipients()
    const { isPurchaseOrderPointsAvailable } = usePaymentMethods()
    const { isBillPaymentsTemplate } = useProvider()
    const { ewalletLabels, showPointsToBurn } = useCms()

    const isEstore = computed(() => props.item.type === 'estore')
    const isCinema = computed(() => props.item.type === 'voucher')
    const isPointsAvailable = computed(
      () => isPurchaseOrderPointsAvailable.value && showPointsToBurn.value
    )

    return {
      cart,
      updateCartItem,
      removeCartItem,
      calculatePointsToBurnOrder,
      isPointsAvailable,
      getGiftRecipient,
      formatDollar,
      isEstore,
      isCinema,
      isBillPaymentsTemplate,
      ewalletLabels,
    }
  },
  data() {
    return {
      gift: undefined,
      loading: false,
    }
  },
  computed: {
    pointsToBurn() {
      return this.calculatePointsToBurnOrder(this.total)
    },
    image() {
      const retailer = this.item.retailerLogo
      const product = this.item.productImage

      return this.cdn(product || retailer).url()
    },
    title() {
      const { retailerName, name, type } = this.item

      switch (type) {
        case 'dining':
          return name
        default:
          return retailerName
      }
    },
    removeButtonLabel() {
      return `${this.title} remove`
    },
    product() {
      const { physical, value, name } = this.item
      let cardType

      if (this.isEstore) {
        if (this.prependEstoreTitleWithPrice) {
          cardType = name
        } else {
          return name
        }
      } else if (this.isCinema) {
        cardType = 'eVoucher'
      } else if (physical) {
        cardType = 'Physical Gift Card'
      } else {
        cardType = 'eGift Card'
      }

      return `${formatDollar(value)} ${cardType}`
    },
    price() {
      const { price, priceGst } = this.item

      if (this.isEstore) {
        return priceGst
      } else {
        return price
      }
    },
    total() {
      return this.price * this.item.quantity
    },
  },
  watch: {
    'item.giftRecipientId'(id) {
      if (id) this.getGiftDetails()
    },
  },
  async mounted() {
    if (this.item.giftRecipientId) {
      this.getGiftDetails()
    }
  },
  methods: {
    updateItem(counterValue) {
      this.updateCartItem({
        key: this.item.id,
        newState: counterValue,
      })
    },
    removeItem() {
      this.removeCartItem({
        key: this.item.id,
      })
    },
    async getGiftDetails() {
      this.loading = true
      this.gift = await this.getGiftRecipient(this.item.giftRecipientId)
      this.loading = false
    },
  },
}
</script>

<template>
  <div v-if="item">
    <div class="relative mx-5 my-[15px] rounded-xl border bg-white p-[15px]">
      <div class="absolute right-0 top-0 -mr-2.5 -mt-2.5">
        <base-button-round
          class="rounded-full border-4 border-solid"
          :disabled="cart.updating"
          icon="plain/trash"
          icon-size="sm"
          color="error"
          :title="title"
          alt="remove"
          data-test="cart-item-remove"
          @click="removeItem"
        />
      </div>
      <div class="mb-[5px] flex items-center pr-[15px]">
        <div class="mr-2.5 w-20 shrink-0">
          <base-image
            v-if="image"
            :src="image"
            :alt="String(item.value)"
            :fit="isEstore ? 'contain' : 'cover'"
          />
        </div>
        <div>
          <p>
            <span class="font-bold">
              {{ title ? `${title} - ` : '' }}
            </span>
            <span>{{ product }}</span>
          </p>

          <template v-if="loading">
            <div
              class="h-5 w-full animate-pulse rounded-md bg-eonx-neutral-50"
            />
          </template>
          <template v-else>
            <ew-product-type
              v-if="isEstore"
              class="text-eonx-neutral-600"
              physical
              :show-delivery="showDelivery"
            >
              <template #physical>Delivered by supplier</template>
            </ew-product-type>
            <ew-product-type
              v-else-if="item.gift && gift"
              class="text-eonx-neutral-600"
              :gift="item.gift"
              :gift-card="!!item.retailerName"
              :physical="item.physical"
              :show-delivery="showDelivery"
            >
              <template #digital>
                <div>Gift to {{ gift.email }}</div>
              </template>
              <template #physical>
                <div>Gift to {{ gift.name }}</div>
              </template>
            </ew-product-type>
            <ew-product-type
              v-else
              class="text-eonx-neutral-600"
              :gift="item.gift"
              :gift-card="!!item.retailerName"
              :physical="item.physical"
              :show-delivery="showDelivery"
            >
              <template #digital>
                Delivered to
                {{ ewalletLabels.ewallet }}
              </template>
              <template #physical>Delivered by AusPost</template>
            </ew-product-type>
          </template>
        </div>
      </div>

      <div class="mt-2.5 flex justify-between">
        <div class="flex items-end">
          <base-counter
            class="grow"
            :value="item.quantity"
            :disabled="cart.updating"
            rounded
            :min="1"
            :max="10"
            @input="updateItem"
          />
        </div>
        <cart-item-price
          :total="total"
          :is-points-available="isPointsAvailable"
          :points-to-burn="pointsToBurn"
        />
      </div>
    </div>
  </div>
</template>
